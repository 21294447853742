import './Main.scss';

import TransparentFixedEn from '../../../components/EN/headers/TransparentFixed/TransparentFixed';
import BlackAndWhiteEn from '../../../components/EN/footers/BlackAndWhite/BlackAndWhite';

import SilverTruck from '../../../assets/images/trucks/trucks_11.jpeg';
import WhiteTruck from '../../../assets/images/trucks/trucks_2.jpg';
import RedTruck from '../../../assets/images/trucks/trucks_3.jpg';

const Index = () => {

    function setAgeOfCompany() {
        const nowYear = new Date().getFullYear();
        const startYear = new Date(1995);
        const diffTime = nowYear - startYear;

        if (diffTime % 10 === 1) {
            return diffTime + ' year on market';
        } else {
            return diffTime + ' years on market';
        }
    }

    function setQuantityOfKilometers() {
        const nowYear = new Date().getFullYear();
        const startYear = new Date(2022);
        let countQuantity = 10 + ((nowYear - startYear) * 2);

        return countQuantity + ' mln. of kilometers';
    }

    return (
        <>
            < TransparentFixedEn />
            <main className="main-container-carousel">
                <div className="container-carousel">
                    <div id="carousel" className="carousel slide" data-bs-ride="true">
                        <div className="carousel-inner">
                            <div className="carousel-item active" data-bs-interval="5000">
                                <img src={SilverTruck} className="d-block custom-height"
                                    alt="Silver color truck on the road, around is forest" />
                            </div>
                            <div className="carousel-item" data-bs-interval="5000">
                                <img src={WhiteTruck} className="d-block custom-height"
                                    alt="White color truck on the road, around is forest" />
                            </div>
                            <div className="carousel-item" data-bs-interval="5000">
                                <img src={RedTruck} className="d-block custom-height"
                                    alt="Red truck on the road, the sun is setting around the green fields" />
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button" data-bs-target="#carousel"
                            data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#carousel"
                            data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>
            </main>
            <main className="main-container-advantages">
                <div className="container-advantages">
                    <div className="hero-box">
                        <div className="center-container">
                            <div className="hero-box-all box-left-top">
                                <h3 className="years-in-business">{setAgeOfCompany()}</h3>
                            </div>
                            <div className="hero-box-all box-right-top">
                                <h3 className="millions-of-km">{setQuantityOfKilometers()}</h3>
                            </div>
                            <div className="hero-box-all box-left-bottom">
                                <h3>30&nbsp;Europe&nbsp;countries</h3>
                            </div>
                            <div className="hero-box-all box-right-bottom">
                                <h3>Own&nbsp;repair workshop</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            < BlackAndWhiteEn />
        </>
    );
}

export default Index;