import './Main.scss';

import TransparentFixed from '../../components/headers/TransparentFixed/TransparentFixed';
import BlackAndWhite from '../../components/footers/BlackAndWhite/BlackAndWhite';

import SilverTruck from '../../assets/images/trucks/trucks_11.jpeg';
import WhiteTruck from '../../assets/images/trucks/trucks_2.jpg';
import RedTruck from '../../assets/images/trucks/trucks_3.jpg';

const Index = () => {

    function setAgeOfCompany() {
        const nowYear = new Date().getFullYear();
        const startYear = new Date(1995);
        const diffTime = nowYear - startYear;

        if (diffTime % 10 === 0) {
            return diffTime + ' metų rinkoje';
        } else {
            return diffTime + ' metai rinkoje';
        }
    }

    function setQuantityOfKilometers() {
        const nowYear = new Date().getFullYear();
        const startYear = new Date(2022);
        let countQuantity = 10 + ((nowYear - startYear) * 2);

        return countQuantity + ' mln. kilometrų';
    }

    return (
        <>
            < TransparentFixed />
            <main className="main-container-carousel">
                <div className="container-carousel">
                    <div id="carousel" className="carousel slide" data-bs-ride="true">
                        <div className="carousel-inner">
                            <div className="carousel-item active" data-bs-interval="5000">
                                <img src={SilverTruck} className="d-block custom-height"
                                    alt="Sunkvežimis sidabro spalvos važiuoja keliu aplink miškas" />
                            </div>
                            <div className="carousel-item" data-bs-interval="5000">
                                <img src={WhiteTruck} className="d-block custom-height"
                                    alt="Sunkvežimis baltos spalvos važiuoja keliu aplink miškas ypač žalias" />
                            </div>
                            <div className="carousel-item" data-bs-interval="5000">
                                <img src={RedTruck} className="d-block custom-height"
                                    alt="Sunkvežimis bordinės spalvos važiuoja keliu aplink žalias laukas saulė leidžiasi" />
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button" data-bs-target="#carousel"
                            data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#carousel"
                            data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>
            </main>
            <main className="main-container-advantages">
                <div className="container-advantages">
                    <div className="hero-box">
                        <div className="center-container">
                            <div className="hero-box-all box-left-top">
                                <h3 className="years-in-business">{setAgeOfCompany()}</h3>
                            </div>
                            <div className="hero-box-all box-right-top">
                                <h3 className="millions-of-km">{setQuantityOfKilometers()}</h3>
                            </div>
                            <div className="hero-box-all box-left-bottom">
                                <h3>30&nbsp;Europos&nbsp;šalių</h3>
                            </div>
                            <div className="hero-box-all box-right-bottom">
                                <h3>Nuosavos&nbsp;remonto dirbtuvės</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <BlackAndWhite />
        </>
    );
}

export default Index;