import './About.scss';

import TransparentFixed from '../../components/headers/TransparentFixed/TransparentFixed';
import BlackAndWhite from '../../components/footers/BlackAndWhite/BlackAndWhite';

const About = () => {

    return (
        <>
            <TransparentFixed />
            <section className="background-container-timeline">
                <div className="container">
                    <div className="timeline-wrapper">
                        <h1>Įmonės plėtra</h1>
                        <ul className="timeline-events">
                            <li className="timeline-event-years-3">
                            </li>
                            <li className="timeline-event-years-3">
                                <h2>1995 metais</h2>
                                <h3>Įkurta įmonė</h3>
                                <h4>UAB MELNIKO AUTO</h4>
                            </li>
                            <li className="timeline-event-years-3">
                                <h2>1998-2001</h2>
                                <h3>Įmonėje dirba kiti šeimos nariai</h3>
                                <h4>Šeimos verslo įmonė</h4>
                            </li>
                            <li className="timeline-event-years-3">
                                <h2>2001-2004</h2>
                                <h3>Verslo geografija</h3>
                                <h4>Lietuva, Latvija, Estija</h4>
                            </li>
                            <li className="timeline-event-years-3">
                                <h2>2004-2007</h2>
                                <h3>Verslo rinkos</h3>
                                <h4>Daugelis ES valstybių</h4>
                            </li>
                            <li className="timeline-event-years-3">
                                <h2>2007-2010</h2>
                                <h3>Darbuotojų</h3>
                                <h4>Daugiau nei 10</h4>
                            </li>
                            <li className="timeline-event-years-3">
                                <h2>2010-2013</h2>
                                <h3>Įkurtos nuosavos remonto dirbtuvės</h3>
                                <h4>Parkas padidėjo iki 10 automobilių</h4>
                            </li>
                            <li className="timeline-event-years-3">
                                <h2>2013-2016</h2>
                                <h3>Apyvarta</h3>
                                <h4>Daugiau nei 0,5 mln. Euro</h4>
                            </li>
                            <li className="timeline-event-years-3">
                                <h2>2016-2019</h2>
                                <h3>Apyvarta</h3>
                                <h4>Daugiau nei 1 mln. Euro</h4>
                            </li>
                            <li className="timeline-event-years-3">
                                <h2>2019-2022</h2>
                                <h3>Darbuotojų</h3>
                                <h4>Daugiau nei 20</h4>
                            </li>
                        </ul>
                        <ul className="timelines-years">
                            <li>1995</li>
                            <li>1998</li>
                            <li>2001</li>
                            <li>2004</li>
                            <li>2007</li>
                            <li>2010</li>
                            <li>2013</li>
                            <li>2016</li>
                            <li>2019</li>
                            <li>2022</li>
                        </ul>
                    </div>
                </div>
            </section>
            <BlackAndWhite />
        </>
    );
}

export default About;