import './Services.scss'

import TransparentFixedEn from '../../../components/EN/headers/TransparentFixed/TransparentFixed';
import BlackAndWhiteEn from '../../../components/EN/footers/BlackAndWhite/BlackAndWhite';

import { useEffect, useState, useRef } from 'react';
import { useParams } from "react-router-dom";
import axios from 'axios';

const Services = () => {

    const scrollToElement = useRef();

    const [isSuccess, setIsSuccess] = useState(false);

    let { id } = useParams();

    const [service, setService] = useState(() => {
        const saved = localStorage.getItem('service');
        const initialValue = saved;
        return initialValue || id || false;
    });

    const [firstName, setFirstName] = useState(() => {
        const saved = localStorage.getItem('firstName');
        const initialValue = saved;
        return initialValue || '';
    });

    const [phone, setPhone] = useState(() => {
        const saved = localStorage.getItem('phone');
        const initialValue = saved;
        return initialValue || '';
    });

    const [mail, setMail] = useState(() => {
        const saved = localStorage.getItem('mail');
        const initialValue = saved;
        return initialValue || '';
    });

    const [text, setText] = useState(() => {
        const saved = localStorage.getItem('text');
        const initialValue = saved;
        return initialValue || '';
    });

    const clearFormValues = () => {
        setService(false);
        setFirstName('');
        setPhone('');
        setMail('');
        setText('');

        localStorage.setItem('service', false);
        localStorage.removeItem('firstName');
        localStorage.removeItem('phone');
        localStorage.removeItem('mail');
        localStorage.removeItem('text');
    }

    const handleSubmit = event => {
        event.preventDefault();
        (isSuccess === false) && setIsSuccess(true);
        setTimeout(() => setIsSuccess(false), 5000);

        const formData = new FormData();
        formData.append('_captcha', false);
        formData.append('service', service);
        formData.append('firstName', firstName);
        formData.append('phone', phone);
        formData.append('mail', mail);
        formData.append('text', text);

        axios.post("https://formsubmit.co/3acebf40dc095bfbc357719cc67997df", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })

        clearFormValues();
    };

    const serviceHandler = (position) => {
        setService(position);
        scrollToElement.current.scrollIntoView({ behavior: "smooth", block: "center" });
        scrollToElement.current.focus();
    }

    useEffect(() => {
        localStorage.setItem('service', service);
        localStorage.setItem('firstName', firstName);
        localStorage.setItem('phone', phone);
        localStorage.setItem('mail', mail);
        localStorage.setItem('text', text);
    }, [service, firstName, phone, mail, text]);

    useEffect(() => {
        if (id) {
            setService(id);
            scrollToElement.current.scrollIntoView({ behavior: "smooth", block: "center" });
            scrollToElement.current.focus();
        }
    }, [id]);

    return (
        <>
            < TransparentFixedEn />
            <main className="main-container-services">
                {isSuccess && <div className="success-message">Your information has been successfully sent, we will contact you soon!</div>}
                <div className="container-services">
                    <div className="custom-field">
                        <h1>
                            <span>
                                Services
                            </span>
                        </h1>
                        <div className="services-wrapper">
                            <div className="center-container">
                                <div className="services service-first" onClick={() => serviceHandler('used-transport')}>
                                    <h4>Used&nbsp;trucks and&nbsp;trailers</h4>
                                </div>
                                <div className="services service-second" onClick={() => serviceHandler('transportation-cost')}>
                                    <h4>Transportation prices</h4>
                                </div>
                                <div className="services service-third" onClick={() => serviceHandler('transport-maintenance')}>
                                    <h4>Repair&nbsp;of&nbsp;trucks and&nbsp;trailers</h4>
                                </div>
                                <div className="services service-fourth" onClick={() => serviceHandler('transport-rent')}>
                                    <h4>Transport&nbsp;rental</h4>
                                </div>
                                <div className="services service-fifth" onClick={() => serviceHandler('logistics-service')}>
                                    <h4>Logistics&nbsp;services</h4>
                                </div>
                                <div className="services service-sixth" onClick={() => serviceHandler('consulting')}>
                                    <h4>Consulting</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <div className="background-container-services">
                <div className="container">
                    <div className="services-form-wrapper">
                        {isSuccess && <div className="success-message">Jūsų informacija buvo sėkmingai persiųsta, netrukus su Jumis susisieksime!</div>}
                        <form onSubmit={handleSubmit} className="services-form" action="https://formsubmit.co/andzejus.naimovicius@gmail.com" method="POST">
                            <input type="text" name="_honey" style={{ display: "none" }} />
                            <input type="hidden" name="_captcha" value="false" />

                            <div className="services-input-wrapper">
                                <select name="service" id="service" value={service} onChange={event => setService(event.target.value)}>
                                    <option value={false} hidden>Choose the question you are interested in</option>
                                    <option value="used-transport" id="used-transport">Used trucks and trailers</option>
                                    <option value="transportation-cost" id="transportation-cost">Transportation prices</option>
                                    <option value="transport-maintenance" id="transport-maintenance">Repair of trucks and trailers</option>
                                    <option value="transport-rent" id="transport-rent">Transport rental</option>
                                    <option value="logistics-service" id="logistics-service">Logistics services</option>
                                    <option value="consulting" id="consulting">Consulting</option>
                                </select>
                            </div>
                            <div className="name-input-wrapper">
                                <input ref={scrollToElement} value={firstName} onChange={event => setFirstName(event.target.value)} type="text" id="firstName" name="firstName" placeholder="Name" required />
                            </div>
                            <div className="phone-input-wrapper">
                                <input value={phone} onChange={event => setPhone(event.target.value)} type="tel" id="phone" name="phone" placeholder="Phone number" required />
                            </div>
                            <div className="mail-service-wrapper">
                                <input value={mail} onChange={event => setMail(event.target.value)} type="email" id="mail" name="mail" placeholder="E-mail address" />
                            </div>
                            <div className="textarea-service-wrapper">
                                <textarea value={text} onChange={event => setText(event.target.value)} placeholder="Your information:" rows="8" cols="50" id="text" name="text"></textarea>
                            </div>
                            <div className="buttons-service-wrapper">
                                <button onClick={clearFormValues} type="reset" className="secondary outline" id="reset-service">Delete</button>
                                <button type="submit" className="primary outline">Send</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <BlackAndWhiteEn />
        </>
    );
}

export default Services;