import './TransparentFixed.scss';

import { useState } from 'react';

import LogoBig from '../../../../assets/images/logo/melniko_auto_horizontal_tr.png';
import LogoSmall from '../../../../assets/images/logo/melniko_auto_hor_tr_small.png';
import CallTo from '../../../communication/CallTo/CallTo';
import EmailTo from '../../../communication/EmailTo/EmailTo';
import { ReactComponent as Lithuanian } from '../../../../assets/images/svg/lt.svg';

import { Link } from 'react-router-dom';

const TransparentFixed = () => {

    const [isActive, setIsActive] = useState(false);

    const hamburgerHandler = () => {
        isActive === false ? setIsActive(true) : setIsActive(false);
    }

    const currentUrlLocation = window.location.pathname;
    let result = currentUrlLocation.split('/');

    return (
        <header className="main-header">
            <div className="container">
                <div className="main-header-content-wrapper">
                    <div className="logo-wrapper">
                        <Link to={'/en'}>
                            <img src={LogoBig} index="big-logo" alt="Logo UAB Melniko Auto" />
                            <img src={LogoSmall} index="small-logo" alt="Logo UAB Melniko Auto" />
                        </Link>
                        <div className={isActive ? 'hamburger active' : 'hamburger'} onClick={hamburgerHandler}>
                            <span className="bar"></span>
                            <span className="bar"></span>
                            <span className="bar"></span>
                        </div>
                    </div>
                    <nav className="main-navigation">
                        <ul className={isActive ? 'main-menu active' : 'main-menu'}>
                            <li className="menu-item"><Link className="nav-link" onClick={hamburgerHandler} to={'/en'}>Main</Link></li>
                            <li className="menu-item"><Link className="nav-link" onClick={hamburgerHandler} to={'/about/en'}>About</Link></li>
                            <li className="menu-item"><Link className="nav-link" onClick={hamburgerHandler} to={'/services/en'}>Services</Link></li>
                            <li className="menu-item"><Link className="nav-link" onClick={hamburgerHandler} to={'/career/en'}>Career</Link></li>
                            <li className="menu-item"><Link className="nav-link" onClick={hamburgerHandler} to={result[1] !== 'en' ? ('/' + result[1]) : ('/')}>
                                <div className="lithuanian"><Lithuanian /><span>&nbsp;&nbsp;LT</span></div></Link></li>
                        </ul>
                        <div className="contact-wrapper">
                            <CallTo phone='+37066366555' addClass='button outline'>Call</CallTo>
                            <EmailTo email='info@melnikoauto.lt'>Write&nbsp;mail</EmailTo>
                        </div>
                    </nav>
                </div>
            </div>
        </header>
    );
}

export default TransparentFixed;