import './Career.scss';

import TransparentFixedEn from '../../../components/EN/headers/TransparentFixed/TransparentFixed';
import BlackAndWhiteEn from '../../../components/EN/footers/BlackAndWhite/BlackAndWhite';

import { useEffect, useState, useRef } from 'react';

import axios from 'axios';

const Career = () => {

    const scrollToElement = useRef();

    const [isSuccess, setIsSuccess] = useState(false);

    const [firstName, setFirstName] = useState(() => {
        const saved = localStorage.getItem('firstName');
        const initialValue = saved;
        return initialValue || '';
    });

    const [lastName, setLastName] = useState(() => {
        const saved = localStorage.getItem('lastName');
        const initialValue = saved;
        return initialValue || '';
    });

    const [phone, setPhone] = useState(() => {
        const saved = localStorage.getItem('phone');
        const initialValue = saved;
        return initialValue || '';
    });
    const [mail, setMail] = useState(() => {
        const saved = localStorage.getItem('mail');
        const initialValue = saved;
        return initialValue || '';
    });
    const [position, setPosition] = useState(() => {
        const saved = localStorage.getItem('position');
        const initialValue = saved;
        return initialValue || false;
    });
    const [experience, setExperience] = useState(() => {
        const saved = localStorage.getItem('experience');
        const initialValue = saved;
        return initialValue || '';
    });
    const [text, setText] = useState(() => {
        const saved = localStorage.getItem('text');
        const initialValue = saved;
        return initialValue || '';
    });

    const clearFormValues = () => {
        setFirstName('');
        setLastName('');
        setPhone('');
        setMail('');
        setPosition(false);
        setExperience('');
        setText('');
        localStorage.removeItem('firstName');
        localStorage.removeItem('lastName');
        localStorage.removeItem('phone');
        localStorage.removeItem('mail');
        localStorage.setItem('position', false);
        localStorage.removeItem('experience');
        localStorage.removeItem('text');
    }

    const handleSubmit = event => {
        event.preventDefault();
        (isSuccess === false) && setIsSuccess(true);
        setTimeout(() => setIsSuccess(false), 5000);

        const formData = new FormData();
        formData.append('_captcha', false);
        formData.append('firstName', firstName);
        formData.append('lastName', lastName);
        formData.append('phone', phone);
        formData.append('mail', mail);
        formData.append('position', position);
        formData.append('experience', experience);
        formData.append('text', text);

        axios.post("https://formsubmit.co/3acebf40dc095bfbc357719cc67997df", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })

        clearFormValues();
    };

    const positionHandler = (position) => {
        setPosition(position);
        scrollToElement.current.scrollIntoView({ behavior: "smooth", block: "center" });
        scrollToElement.current.focus();
    }

    useEffect(() => {
        localStorage.setItem('firstName', firstName);
        localStorage.setItem('lastName', lastName);
        localStorage.setItem('phone', phone);
        localStorage.setItem('mail', mail);
        localStorage.setItem('position', position);
        localStorage.setItem('experience', experience);
        localStorage.setItem('text', text);
    }, [firstName, lastName, phone, mail, position, experience, text]);

    return (
        <>
            < TransparentFixedEn />
            <main className="main-container-career">
                <div className="container-career">
                    <div className="custom-field">
                        <h1>
                            <span>
                                Job openings
                            </span>
                        </h1>
                        <div className="career-box-wrapper">
                            <div className="center-container">
                                <div className="career-box box-first" onClick={() => positionHandler('driver-eu')}>
                                    <div className="text-box">
                                        <h4>Driver</h4>
                                        <h6>(For&nbsp;work&nbsp;in&nbsp;Western&nbsp;Europe)</h6>
                                    </div>
                                </div>
                                <div className="career-box box-second" onClick={() => positionHandler('driver-nord')}>
                                    <div className="text-box">
                                        <h4>Driver</h4>
                                        <h6>(For&nbsp;work&nbsp;in&nbsp;the&nbsp;Baltic&nbsp;Region)</h6>
                                    </div>
                                </div>
                                <div className="career-box box-third" onClick={() => positionHandler('manager-clients')}>
                                    <div className="text-box">
                                        <h4>Manager</h4>
                                        <h6>(For&nbsp;work&nbsp;with&nbsp;clients)</h6>
                                    </div>
                                </div>
                                <div className="career-box box-fourth" onClick={() => positionHandler('manager-drivers')}>
                                    <div className="text-box">
                                        <h4>Manager</h4>
                                        <h6>(For&nbsp;work&nbsp;with&nbsp;drivers)</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <div className="background-container-form">
                <div className="container">
                    <div className="form-wrapper">
                        {isSuccess && <div className="success-message">Your information has been successfully sent, we will contact you soon!</div>}
                        <form onSubmit={handleSubmit} className="career-form">

                            <input type="text" name="_honey" style={{ display: "none" }} />
                            <input type="hidden" name="_captcha" value="false" />

                            <div className="first-name-input-wrapper">
                                <input type="text" id="firstName" name="firstName" placeholder="Name" required
                                    ref={scrollToElement} value={firstName} onChange={event => setFirstName(event.target.value)} />
                            </div>
                            <div className="last-name-input-wrapper">
                                <input type="text" id="lastName" name="lastName" placeholder="Surname"
                                    value={lastName} onChange={event => setLastName(event.target.value)} />
                            </div>
                            <div className="tel-input-wrapper">
                                <input type="tel" id="phone" name="phone" placeholder="Phone number" required
                                    value={phone} onChange={event => setPhone(event.target.value)} />
                            </div>
                            <div className="mail-input-wrapper">
                                <input type="email" id="mail" name="mail" placeholder="E-mail address"
                                    value={mail} onChange={event => setMail(event.target.value)} />
                            </div>
                            <div className="position-input-wrapper">
                                <div>
                                    <select name="position" id="position"
                                        value={position} onChange={event => setPosition(event.target.value)} >
                                        <option value={false} hidden>Mark the position you are interested in</option>
                                        <option value="driver-eu" id="driver-eu">Driver - For work in Western Europe</option>
                                        <option value="driver-nord" id="driver-nord">Driver - For work in the Baltic Region</option>
                                        <option value="manager-clients" id="manager-clients">Manager - For work with clients</option>
                                        <option value="manager-drivers" id="manager-drivers">Manager - For work with drivers</option>
                                    </select>
                                </div>
                            </div>
                            <div className="experience-input-wrapper">
                                <input type="number" id="experience" name="experience" placeholder="Years of experience"
                                    value={experience} onChange={event => setExperience(event.target.value)} />
                            </div>
                            <div className="textarea-input-wrapper">
                                <textarea placeholder="Your information:" rows="8" cols="50" id="text" name="text"
                                    value={text} onChange={event => setText(event.target.value)} ></textarea>
                            </div>
                            <div className="buttons-career-wrapper">
                                <button type="reset" onClick={clearFormValues} className="button secondary outline" id="reset-career">Delete</button>
                                <button type="submit" className="button outline">Send</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            < BlackAndWhiteEn />
        </>
    );
}

export default Career;