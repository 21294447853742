import './App.scss';

import Main from './pages/Main/Main';
import About from './pages/About/About';
import Services from './pages/Services/Services';
import Career from './pages/Career/Career';
import MainEn from './pages/EN/Main/Main';
import AboutEn from './pages/EN/About/About';
import CareerEn from './pages/EN/Career/Career';
import ServicesEn from './pages/EN/Services/Services';

import { Routes, Route } from 'react-router-dom';

function App() {

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={< Main />} />
        <Route path="/about" element={< About />} />
        <Route path="/services" element={< Services />} />
        <Route path="/services/:id" element={< Services />} />
        <Route path="/career" element={< Career />} />
        <Route path="/en" element={< MainEn />} />
        <Route path="/about/en" element={< AboutEn />} />
        <Route path="/career/en" element={< CareerEn />} />
        <Route path="/services/en" element={< ServicesEn />} />
        <Route path="/services/en/:id" element={< ServicesEn />} />
        <Route path="*" element={< Main />} />
      </Routes>
    </div>
  );
}

export default App;