import './About.scss';

import TransparentFixedEn from '../../../components/EN/headers/TransparentFixed/TransparentFixed';
import BlackAndWhiteEn from '../../../components/EN/footers/BlackAndWhite/BlackAndWhite';

const About = () => {

    return (
        <>
            <TransparentFixedEn />
            <section className="background-container-timeline">
                <div className="container">
                    <div className="timeline-wrapper">
                        <h1>Development of the company</h1>
                        <ul className="timeline-events">
                            <li className="timeline-event-years-3">
                            </li>
                            <li className="timeline-event-years-3">
                                <h2>1995 year</h2>
                                <h3>Established company</h3>
                                <h4>UAB MELNIKO AUTO</h4>
                            </li>
                            <li className="timeline-event-years-3">
                                <h2>1998-2001</h2>
                                <h3>Other family members work in the company</h3>
                                <h4>Family business company</h4>
                            </li>
                            <li className="timeline-event-years-3">
                                <h2>2001-2004</h2>
                                <h3>Business geography</h3>
                                <h4>Lithuania, Latvia, Estonia</h4>
                            </li>
                            <li className="timeline-event-years-3">
                                <h2>2004-2007</h2>
                                <h3>Business markets</h3>
                                <h4>Almost all EU countries</h4>
                            </li>
                            <li className="timeline-event-years-3">
                                <h2>2007-2010</h2>
                                <h3>Employees</h3>
                                <h4>More than 10</h4>
                            </li>
                            <li className="timeline-event-years-3">
                                <h2>2010-2013</h2>
                                <h3>Established own repair workshop</h3>
                                <h4>The fleet increased to 10 cars</h4>
                            </li>
                            <li className="timeline-event-years-3">
                                <h2>2013-2016</h2>
                                <h3>Turnover</h3>
                                <h4>More than 0.5 million Euro</h4>
                            </li>
                            <li className="timeline-event-years-3">
                                <h2>2016-2019</h2>
                                <h3>Turnover</h3>
                                <h4>More than 1 million Euro</h4>
                            </li>
                            <li className="timeline-event-years-3">
                                <h2>2019-2022</h2>
                                <h3>Employees</h3>
                                <h4>More than 20</h4>
                            </li>
                        </ul>
                        <ul className="timelines-years">
                            <li>1995</li>
                            <li>1998</li>
                            <li>2001</li>
                            <li>2004</li>
                            <li>2007</li>
                            <li>2010</li>
                            <li>2013</li>
                            <li>2016</li>
                            <li>2019</li>
                            <li>2022</li>
                        </ul>
                    </div>
                </div>
            </section>
            <BlackAndWhiteEn />
        </>
    );
}

export default About;