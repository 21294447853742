import './BlackAndWhite.scss';

import { ReactComponent as Instagram } from '../../../assets/images/svg/instagram.svg';
import { ReactComponent as Facebook } from '../../../assets/images/svg/facebook.svg';
import { ReactComponent as Linkedin } from '../../../assets/images/svg/linkedin.svg';
import { ReactComponent as Twitter } from '../../../assets/images/svg/twitter.svg';

import CallTo from '../../communication/CallTo/CallTo';

import { Link } from 'react-router-dom';

const BlackAndWhite = () => {

    return (
        <footer className="footer-container">
            <div className="container">
                <div className="footer-content-wrapper">
                    <div className="social-media-wrapper">
                        <div className="social-media">
                            <div className="instagram-logo top-logos">
                                <Link to={'/#'}>
                                    <Instagram />
                                </Link>
                            </div>
                            <div className="facebook-logo top-logos">
                                <Link to={'/#'}>
                                    <Facebook />
                                </Link>
                            </div>
                            <div className="linkedin-logo top-logos">
                                <Link to={'/#'}>
                                    <Linkedin />
                                </Link>
                            </div>
                            <div className="twitter-logo top-logos">
                                <Link to={'/#'}>
                                    <Twitter />
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="structure-contact-wrapper">
                        <div className='structure-wrapper'>
                            <h5>Naudingos nuorodos</h5>
                            <div className="menu-duplicate">
                                <ul>
                                    <li><Link to={'/'}>Pagrindinis</Link></li>
                                    <li><Link to={'/about'}>Apie mus</Link></li>
                                    <li><Link to={'/services'}>Paslaugos</Link></li>
                                    <li><Link to={'/career'}>Karjera</Link></li>
                                </ul>
                            </div>
                            <div className="interesting-links">
                                <ul>
                                    <li><Link to={'/services/used-transport'}>Naudoti sunkvežimiai ir priekabos</Link></li>
                                    <li><Link to={'/services/transportation-cost'}>Transportavimo kainos</Link></li>
                                    <li><Link to={'/services/transport-maintenance'}>Sunkvežimių ir priekabų remontas</Link></li>
                                    <li><Link to={'/services/transport-rent'}>Transporto nuoma</Link></li>
                                    <li><Link to={'/services/logistics-service'}>Logistikos paslaugos</Link></li>
                                    <li><Link to={'/services/consulting'}>Konsultavimas</Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className="contact-information">
                            <h5>Kita informacija</h5>
                            <div className="address-info">
                                <h6>Adresas</h6>
                                <address>
                                    <ul>
                                        <li><a href="https://goo.gl/maps/NzG1ozD6qXiRDoZKA" target="_blank" rel="noopener noreferrer">Lyglaukių g. 13,</a></li>
                                        <li><a href="https://goo.gl/maps/NzG1ozD6qXiRDoZKA" target="_blank" rel="noopener noreferrer">LT-01215,</a></li>
                                        <li><a href="https://goo.gl/maps/NzG1ozD6qXiRDoZKA" target="_blank" rel="noopener noreferrer">Vilnius</a></li>
                                    </ul>
                                </address>
                            </div>
                            <div className="contacts">
                                <h6>Kontaktai</h6>
                                <ul>
                                    <li><CallTo phone='+37052153467'>Tel.: +370 5 2 153467</CallTo></li>
                                    <li><CallTo phone='+37064874284'>Mob.: +370 648 74284</CallTo></li>
                                    <li><CallTo phone='+37052153467'>Faksas: +370 5 2 153467</CallTo></li>
                                </ul>
                            </div>
                            <div className="company-data">
                                <h6>Įmonės informacija</h6>
                                <ul>
                                    <li>Įmonės kodas: 123096943</li>
                                    <li>PVM mokėtojo kodas: LT230969413</li>
                                    <li>Registracijos data: 1995.03.21</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="copyright"><p>Copyright © 2022 All Rights Reserved</p></div>
                    <div className="creator"><p>Designed and created by&nbsp;&nbsp;<span><a href="https://andzejusn.github.io/CV/" target="_blank" rel="noreferrer"><span className="g">G</span><span
                        className="r">r</span><span className="a">a</span><span className="v">v</span><span className="i">i</span><span
                            className="t">t</span></a></span></p></div>
                </div>
            </div>
        </footer>
    );
}

export default BlackAndWhite;